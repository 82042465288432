.data-round {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 14px 14px 14px;
    font-size: 32px;
    font-weight: 800;
}

.data-round-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

}